import React from "react"
import { graphql } from "gatsby"

export default function Home({ data }) {
  const tweetCollection = data.allAirtable.edges

  return (
    <div>
      {tweetCollection.map((tweet, i) => (
        <div
          key={`tweet-${i}`}
          dangerouslySetInnerHTML={{
            __html: tweet.node.fields.tweetpayload.html,
          }}
        />
      ))}
    </div>
  )
}

export const tweetListQuery = graphql`
  query TweetQueryList {
    allAirtable {
      edges {
        node {
          id
          data {
            Handle
            Name
            Tweet
          }
          fields {
            tweetpayload {
              html
              author_name
            }
          }
        }
      }
    }
  }
`
